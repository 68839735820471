import { connect } from "react-redux";
import { AsyncSmartDPContainer, AsyncStopoverContainer } from "app/routes";
import { Navigate } from "react-router-dom";
import { WEBSITE_TYPE } from "app/constants";
import PropTypes from "prop-types";
import { websiteTypeShape } from "app/utils/propTypes";
import { getFallbackRedirectUrl, getWebsiteType } from "app/reducers/partnerSelector";
import { isAbsoluteUrl } from "app/utils/urlDataExtractor";
import { useEffect, useState } from "react";

const RootContainer = ({ websiteType, fallbackUrl }) => {
	const [
		shouldRedirectToExternalFallbackUrl,
		toggleShouldRedirectToExternalFallbackUrl,
	] = useState(false);

	useEffect(() => {
		if (websiteType === WEBSITE_TYPE.LISTING && isAbsoluteUrl(fallbackUrl)) {
			window.location = fallbackUrl;
			toggleShouldRedirectToExternalFallbackUrl(true);
		}
	}, [fallbackUrl, websiteType]);

	if (!shouldRedirectToExternalFallbackUrl) {
		if (websiteType === WEBSITE_TYPE.HOLIDAYS) {
			return <AsyncSmartDPContainer />;
		} else if (websiteType === WEBSITE_TYPE.STOPOVER) {
			return <AsyncStopoverContainer />;
		}

		return (
			<Navigate
				to={{
					pathname: fallbackUrl,
				}}
			/>
		);
	}

	return null;
};

RootContainer.propTypes = {
	websiteType: websiteTypeShape,
	fallbackUrl: PropTypes.string,
};

const mapStateToProps = state => {
	return {
		websiteType: getWebsiteType(state),
		fallbackUrl: getFallbackRedirectUrl(state),
	};
};

export default connect(mapStateToProps)(RootContainer);
